
.cargaXMLClass .modal-dialog {
    max-width: 1200px;  
  }

  .vincularXMLFactura .modal-dialog {
    max-width: 500px;  
  }

  .mostrarPreciosDocumentoPopup .modal-dialog {
    max-width: 500px;  
  }
  
  .verFactura .modal-dialog {
    max-width: 1200px;
  }
  
  .verPoliza .modal-dialog {
    max-width: 1200px;
  }
  
  .pagarCobranzaClass .modal-dialog {
    max-width: 1300px;  
  }
  
  .agregarLoteClass .modal-dialog {
    max-width: 700px;  
  }
  
  .detalleSaldoClass .modal-dialog {
    max-width: 1400px;  
  }
  
  .agregarActualizarCuentaContable .modal-dialog {
    max-width: 1000px;  
  }
  
  .agregarActualizarImpuestoxEmpresa .modal-dialog {
    max-width: 1400px;  
  }

  .agregarCargoPopUp .modal-dialog {
    max-width: 650px;  
  }

  .asignarFuncionPopUp .modal-dialog {
    max-width: 880px;  
  }

  .agregarSubGrupoPopUp .modal-dialog {
    max-width: 500px;  
  }

  .agregarLotePopUp .modal-dialog {
    max-width: 400px;  
  }

  .agregarConcecutivoPopUp .modal-dialog {
    max-width: 690px;  
  }

  .agregarConceptoPopUp .modal-dialog {
    max-width: 950px;  
  }

  .agregarConceptoPagoPopUp .modal-dialog {
    max-width: 700px;  
  }

  .agregarUnidadMedidaPopUp .modal-dialog {
    max-width: 800px;  
  }

  .agregarTipoTransaccionPopUp .modal-dialog {
    max-width: 600px;  
  }

  .agregarBodegaPopUp .modal-dialog {
    max-width: 600px;  
  }

  .agregarClasificacionPopUp .modal-dialog {
    max-width: 600px;  
  }
  
  .agregarActualizarTipoMovimientoBanco .modal-dialog {
    max-width: 1300px;  
  }
  
  .agregarActualizarTipoMovimiento .modal-dialog {
    max-width: 1300px;  
  }

  .agregarDocumentostarea .modal-dialog {
    max-width: 1000px;  
  }
  
  .agregarMenuClass .modal-dialog {
    max-width: 700px;  
  }
  
  .verInformacionUsuario .modal-dialog {
    max-width: 600px;
  }

  .solicitudCancelacionClass .modal-dialog {
    max-width: 1100px;  
  }

  .agregarActualizarNodevadesNoEsFamilia .modal-dialog {
    max-width: 700px;  
  }

  .agregarActualizarNodevadesEsFamilia .modal-dialog {
    max-width: 1000px;  
  }

  .verReporteExcel .modal-dialog {
    max-width: 1400px;  
  }

  .reenviarCorreos .modal-dialog {
    max-width: 500px;  
  }

  .libroSalarios .modal-dialog {
    max-width: 1100px;  
  }

  .libroIncapacidad .modal-dialog {
    max-width: 1150px;  
  }

  .libroAusentismo .modal-dialog {
    max-width: 1000px;  
  }

  .generarPrestamo .modal-dialog {
    max-width: 850px;  
  }

  .generarIncidencia .modal-dialog {
    max-width: 1200px;  
  }

  .cambioSueldoEmpleados .modal-dialog {
    max-width: 1100px;  
  }

  .agregarPagoPrestamo .modal-dialog {
    max-width: 750px;  
  }

  .agregarProducto .modal-dialog {
    max-width: 1100px;  
  }

  .agregarVendedor .modal-dialog {
    max-width: 1000px;  
  }

  .agregarCalendario .modal-dialog {
    max-width: 800px;  
  }

  .agregarIncapacidad .modal-dialog {
    max-width: 1000px;  
  }

  .agregarAusencia .modal-dialog {
    max-width: 1000px;  
  }

  .editarAvatar .modal-dialog {
    max-width: 1000px;  
  }

  .salarioModificado .modal-dialog {
    max-width: 700px;  
  }

  .modalEmoticons .modal-dialog {
    max-width: 390px;  
  }

  .crearGrupoChat .modal-dialog {
    max-width: 600px;  
  }

  .agregarConsecutivoClass .modal-dialog {
    max-width: 500px;  
  }

  .comprarTimbresClass .modal-dialog {
    max-width: 500px;  
  }

  .editarNombreEmpresa .modal-dialog {
    max-width: 500px;  
  }

  .historialUsoTimbres .modal-dialog {
    max-width: 900px;  
  }

  .editarEmpresa .modal-dialog {
    max-width: 900px;
  }

  .menuMasGrande {
    display: inline-block;
     width: 150px; 
     height: 50px !important; 
     overflow: hidden;
      white-space: initial;
  }

  .tiposLicencia .modal-dialog {
    max-width: 1100px;
    
    .modal-content {
      background-color: bisque;
    }
  }

  .urlCargo .modal-dialog {
    max-width: 800px;  
  }
  